<template>
  <div />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Kakao",
  data() {
    return {
      message: "Hello World",
    };
  },
  mounted() {
    const code = this.$route.query.code;
    if (code) {
      this.onLogin(code);
    } else {
      this.onBack();
    }
  },
  methods: {
    async onLogin(code) {
      try {
        await this.social({
          site: "kakao",
          code,
          redirect_url: (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_SNS_LOGIN_BASEURL_LOCAL : process.env.VUE_APP_SNS_LOGIN_BASEURL_SERVER) + "/kakao",
        });
      } catch (e) {
        this.onBack();
      }
    },
    onBack() {
      this.$toast.open({ message: "카카오 로그인 실패", type: "error" });
      this.$router.push({ name: "Login" });
    },
    ...mapActions("user", ["social"]),
  },
};
</script>

<style></style>
